.footer-main{
    white-space: pre-wrap;
    height: 1114px;
    background-color: #0f0f19;
    .p-contact{
        font-weight: 600;
        &:hover{
            opacity: 0.7;
        }
    }
    h2, p{
        color:white;
        margin-bottom: 15px;

    }
    h2{
        padding-top:92px;
    }
    p{
        font-size: 16px;
        line-height: 1.75;
        // letter-spacing: 0.5px;

    }
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ul{
        list-style-type: none;
    }
}
.footer-img{
    padding-bottom: 73px;
    padding-top: 42px;
}
.footer-border-first{
    border: 0.5px solid white;
    opacity: 0.7;
}
.footer-border-second{
    border: 0.5px solid white;
    opacity: 0.7;
    width: 200px;
    margin-bottom:30px;
    margin-top: 0px;
}
.footer-pagelinks{
    display: flex;
    flex-wrap: wrap;
    padding: 0px;
    padding-top: 41px;
    flex-direction: row;
    justify-content: center;
    a{
        padding:10px;
        color: white;
    }
    .nav-link{
        display: block;
        padding: 0.5rem 0.5rem;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.5px;
        color: white;
        &:hover {
            opacity: 0.7;
        }

    }
    .nav-item{
        &.home{
          a{
            color: #ffc03a;
          }
        }
        &.team{
            a{
                color: #ffc03a;
              }
        }
        &.how-we-work{
            a{
                color: #ffc03a;
              }
        }
        &.jobs{
            a{
                color: #ffc03a;
              }
        }
        &.contact{
            a{
                color: #ffc03a;
              }
        }
        &.testimonials{
            a{
                color: #ffc03a;
              }
        }
        &.projects{
            a{
                color: #ffc03a;
              }
        }
        &.gallery{
            a{
                color: #ffc03a;
            }
        }
      }
}
.footer-sociallinks{
    padding: 32px;
    padding-bottom: 0px;
    display: flex;
    justify-content: center;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    img{
        transform: scale(1.5);
        padding: 0px 17px;
        padding-bottom: 40px;
    }
    :hover{
        opacity: 0.7;
    }
}
.footer-copyright{
    color:#ffffff;
    opacity: 0.38;
    font-family: Montserrat;
    padding-top: 37px;
    padding: 0px;
    font-family: sans-serif;
    p{
        font-size: 14px;
    }
}

@media (max-width: 768px) {
    .footer-main{
        height: 780px;
        h2, p{
            text-align: center;
        }
        hr{
            display: none;
        }
        img{
            padding-bottom: 0px;
            padding-top: 20px;
        }
        h2{
            padding-top: 30px;
        }
        .footer-img {
            width: 50%;
        }
        .nav-link {
            font-size: 15px;
            color: white;
            a{
              min-width: 156px;
            }
        }
        .footer-info{
            font-size: 12px;
            margin-bottom: 0px;
            padding: 15px 35px
        }
        .footer-sociallinks{
            padding: 15px 0px;
            img{
                transform: scale(1.2);
            }
        }
        .footer-copyright{
            padding-bottom: 0px;
            margin-bottom: 0px;
            font-family: sans-serif;
            font-size: 12px;
            }
    }
}