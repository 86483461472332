//fun
.fun{
    padding-top: 80px;
    img{
      width: 100%;
    }
  
  }
  .fun-row{
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
  }
  .fun-button{
    width: 180px;
    height: 48px;
    box-shadow: none;
    border-radius: 4px;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0.93px;
    background-color:#ffce45;
    border-color:#ffce45;
    margin-top: 26px;
    margin-bottom: 42px;
    color: #27262e;
  }

  @media (max-width: 768px) {
    .fun{
      br{
        display: block;
      }
      padding-top: 40px;
      img{
        width: 80%;
      }
      .fun-button{
        display: block;
        margin-left: 0;
      }
      h5{
        letter-spacing: 1.17px;
      }
    }
  }