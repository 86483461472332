.beyond-coding{
    padding-bottom: 80px;
    h1{
        padding-bottom: 80px;
    }
    .one-row{
        display:flex;
        flex-wrap: wrap;
        align-items: center;
    }
    .beyond-coding--column{
        display:flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
        padding:0px;
        min-height: 250px;
    }
    .beyond-coding--number{
        color:  #5163c3;
        font-size: 28px;
        font-weight: 600; 
    }
    .beyond-coding--button{
        width: 180px;
        height: 48px;
        box-shadow: none;
        border-radius: 4px;
        font-size: 13px;
        font-weight: bold;
        letter-spacing: 0.93px;
        background-color:#ffce45;
        border-color:#ffce45;
        margin-top: 26px;
        margin-bottom: 42px;
    }
    .client-model-row{
        display: flex;
        flex-direction: row;
    }
}

@media (max-width: 768px) {
    .beyond-coding{
        padding-bottom: 0px;
        h1{
            padding-bottom: 40px;
        }
        .client-model-row{
            display: flex;
            flex-direction: column;
        } 
    }
}