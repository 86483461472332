.testimonials-item{
    display: flex;
    flex-direction: row;
    .testimonials-black{
        padding-right: 0px;
        display: flex;
        flex-direction: column;
        // justify-content: flex-start;
        align-items: flex-start;
        background-color: #0f0f19;
        border-bottom-left-radius: 16px;
        border-top-left-radius: 16px;
        h6, h4, p{
            padding-left: 17px;
            color: white;
            text-align: left;
        }
        img{
            width: 132px;
            height: 115px;
            margin-bottom: 83px;
        }
    }
    .testimonials-white{
        padding-left: 0px;
        box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.08);
        background-color: #ffffff;
        border-bottom-right-radius: 16px;
        border-top-right-radius: 16px;
        .testimonials-white--header{
            display: flex;
            flex-direction: row;
            align-items: center;
            text-align: left;
            .testimonials-white--header-pic{
                height: 80px;
                width: 80px;
                border-radius: 50%;
                background-color: #4f5965;
                margin: 0px;
                box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.08);
            }
        }
        .testimonials-white--text{
            padding-top: 45px;
            height: 230px;
            p{
                padding: 0px;
                margin: 0px;
                text-align: left;
            }
        }
    }

}