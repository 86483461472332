//our-aim
.our-aim{
    h5{
      letter-spacing: 1.17px;
    }
    padding-top: 20px;
    display:flex;
    flex-direction: column;
    p{
      margin-bottom: 60px;
    }
  }
  .our-aim-contact{
    padding-bottom: 20px;
  }
  .our-aim-contact--text{
    padding: 8px;
    padding-right: 10px;
  }
  .our-aim-contact--button{
    border-radius: 50%;
    width: 44px;
    height: 44px;
    background-color: #ffce45;
    border-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-size: large;
    &:hover{
      transform: scale(1.2);
    }
  }
  .our-aim-container--picture{
    display:flex;
    justify-content: space-between;
    object-fit: contain;
    padding-bottom: 80px;
  }
  .our-aim-container--first-picture{
    background: url('https://imagizer.imageshack.com/img922/3166/e1TZYe.png') no-repeat left ;
    height: 515px;
    width: 50%;
    background-size: contain;
  }
  .our-aim-container--second-picture{
    background: url('https://imagizer.imageshack.com/img921/6232/CPRcFn.png') no-repeat right ;
    height: 515px;
    width: 50%;
    background-size: contain;
  }
  .our-aim-card{
    #first-picture, #second-picture{
      display: none;
    }
    display:flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .our-aim-card-row{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;

    .aim-header {
      flex-shrink: 0;
    }
    p{
      padding-bottom: 0px;
      margin-bottom: 0px;
      height: fit-content;
    }
  }

  @media (max-width: 768px) {
    .our-aim-container--picture{
      display: none;
    }
    .our-aim-card{
      #first-picture, #second-picture{
        display: inline;
      }
    }
    #first-picture, #second-picture{
      width: 100%;
      padding-bottom: 0px;
      background-size: contain;
    }
    div#first-picture {order: 1;}
    div#dedication {order: 2;}
    div#second-picture {order: 3;}
    div#teamwork {order: 4;}

    .our-aim-contact{
      padding-bottom: 60px;
      height: 40px;
      display: flex;
      justify-content: space-between;
    }
    br{
      display: none;
    }
    .our-aim-container--first-picture,  .our-aim-container--second-picture{
      height: 300px;
      width: 100%;
    }
    p.p-header{
      margin-bottom: 30px;
    }
    .our-aim-card-row{
      padding: 30px 15px;
    }
  }

  @media (min-width : 768px) and (max-width : 1024px)  {
    .our-aim-container--picture{
      display: none;
    }
    .our-aim-card{
      #first-picture, #second-picture{
        display: inline;
      }
    }
    #first-picture, #second-picture{
      width: 100%;
      padding-bottom: 0px;
      background-size: contain;
    }
    div#first-picture {order: 1;}
    div#dedication {order: 2;}
    div#second-picture {order: 3;}
    div#teamwork {
      order: 4;
      margin-left: 45%;
    }

    .our-aim-contact{
      padding-bottom: 60px;
      height: 40px;
      display: flex;
      justify-content: space-between;
    }
    br{
      display: none;
    }
    .our-aim-container--first-picture,  .our-aim-container--second-picture{
      height: 300px;
      width: 100%;
    }
    p.p-header{
      margin-bottom: 30px;
    }
    .our-aim-card-row{
      padding: 30px 15px;
    }
  }
