.how-we-work{
    img{
        width: 100%;
    }
    h1{
        background: #0f0f19;
        color: white;
        font-size: 40px;
        margin: 0px;
        line-height: 1.58;
        letter-spacing: -2.22px;
        font-weight: bold;
        font-weight: bold;
    }
    h5{
      font-size: 14px;
      line-height: 1.14;
      letter-spacing: -1.17px;
      color: white;
    }
    h2{
        color: white;
    }
    p{
        background: #0f0f19;
        color: white;
        font-size: 16px;
    }
    background: #0f0f19;
    .jump-to-top{
        margin-top: 0px;
        padding-bottom: 30px;
        margin-bottom: 0px;
        img{
            -webkit-filter: invert(100%);
        }
    }
}
@media (max-width: 768px) {
    .how-we-work{
        h1{
            line-height: 1.3;
        }
        p.p-header{
            padding-bottom: 40px;
            line-height: 1.3;
        }
        .jump-to-top{
            margin-top: 0px;
            padding-bottom: 30px;
            margin-bottom: 0px;
            border-bottom: 1px solid #0f0f19;
        }
    }
}


@import "./OurEngagementModel/ourengagementmodel";
@import "./BeyondCoding/beyondcoding";
@import "./SetOfSkills/setofskills";
@import "./WelcomeToOurHome/welcometoourhome";