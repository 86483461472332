.our-engagement-model{
    padding-bottom: 80px;
    h1, p{
        background: #0f0f19;
        color: white;
    }
    h1{
        padding-top: 167px;
    }
    p{  
        padding-bottom: 98px;
        margin: 0px;
    }
    .wrapper {
        position: relative;
        
        .img-container{
            width: 100%;
            position:absolute;
            bottom: 0;
            z-index: 5;
            img{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                position: absolute;
                bottom: 0px;
            }
        }
    }
}

@media (max-width: 768px) {
    .our-engagement-model{
        padding-bottom: 40px;
        br{
            display: none;
        }
        p.p-header{
            text-align: center;
        }
        h1{
            text-align: center;
            line-height: 1.1;
            margin-bottom: 15px;
        }
    }
}