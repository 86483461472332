.gallery{
    h1{
        padding-top: 150px;
        padding-bottom: 100px;
        text-align: center;
    }

    .items-row{
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-around;
    }
    .gallery-item-container{
        object-fit: cover;
        overflow: hidden;
        img{
            width: 100%;
        }
    }
}

@media (max-width: 768px) {
    .gallery{
        h1{
            padding-top: 150px;
            padding-bottom: 60px;
        }
    }
}