// Diversity
.diversity{
    h5{
      letter-spacing: 1.17px;
    }
    padding-top: 80px;
    img{
      width: 100%;
      padding-bottom: 80px;
    }
  
  .diversity-button{
    width: 160px;
    height: 40.7px;
    border-radius: 25px;
    font-size: 10px;
    background-color: #ffce45;
    font-weight: bold;
    color: #27272e;
    letter-spacing: 1px;
    text-align: center;
    border: none;
  }
  .diversity-row{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
  }
  .p-header{
    padding-bottom: 80px;
    margin-bottom: 0px;
  }

  .image-container{
    img{
      z-index: 1;
    }
  }
  .rocher-wrapper {
    position: relative;
  }
  .box {
      position: absolute;
      z-index: 6;
      animation-duration: 2.5s;
      animation-iteration-count: infinite;
      margin: 17% auto 0 22%;
      transform-origin: bottom;
      width: 10%;
      img{
        position: absolute;
      }
  }
  .bounce {
      position: absolute;
      top: 15vh;
      img{
        display: flex;
        justify-content: center;
      }
      animation-name: bounce-rocher;
      animation-timing-function: ease;
  }

  @keyframes bounce-rocher {
    0%   { transform: translateY(0) ; }
    50%  { transform: translateY(-350px) rotate(-90deg) translateX(-150px) ; }
    100% { transform: translateY(0)  rotate(0deg)  translateX(0); }
  }
}
@media (max-width: 768px) {
  .diversity{
    padding-top: 0px;
    img{
      padding-bottom: 40px;
    }
    .one-row{
      display: flex;
      flex-direction: column;
    }
    p.p-header{
      padding-bottom:40px;
    }
    .one-row{
      img{
        width: 70%;
        height: auto;
        display: flex;
        justify-content: flex-start; 
        padding-bottom: 15px;
      }
    }
    .box {
      position: absolute;
      z-index: 6;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      margin: 0% auto 0 23%;
      transform-origin: bottom;
      width: 10%;
      img{
        position: absolute;
      }
    }
    @keyframes bounce-rocher {
      0%   { transform: translateY(0) ; }
      50%  { transform: translateY(-120px) rotate(-90deg) translateX(-50px) ; }
      100% { transform: translateY(0)  rotate(0deg) ; }
    }
  }
}
  