.ants{
    padding-top: 50px;
    h1{
        padding-bottom: 150px;
        text-align: center;
    }
}
.ants-row{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    z-index: 1;
    background: white;
    position: relative;
}

.ant{
    white-space: pre-wrap;
    display: flex;
    flex-direction: column;
    padding-bottom: 80px;
    margin-bottom: 10px;
    h5{
        margin: 0px;
        padding: 0px 0px;
        letter-spacing: 1.17px;
        font-size: 14px;
        text-transform: uppercase;
        text-align: center;
    }
    h4{
        margin: 0px;
        padding: 11px 0px;
        font-size: 32px;
        letter-spacing: -1.33px;
        line-height: 1;
        font-weight: bold;
        text-align: center;
    }
    p{
        line-height: 1.48;
        margin: 0px;
        font-size: 16px;
        font-weight: 300;
        opacity: 0.8;
        min-height: 80px;
    }

}

.ant-img-container{
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 55px;
    width: 100%;
    align-self: center;
    margin-top: 10px;
    .ant-icon {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        transition: all .5s ease;

    }
    .ant-portrait {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        opacity: 0;
        transition: all .5s ease;

    }
}

.ant-img-container:hover{
    .ant-portrait {
        opacity: 1;
        transition: all .5s ease;
    }
    .ant-icon {
        opacity: 0;
        transition: all .5s ease;
    }

}

.hidden-ant{
    width: 41.667%;
    position: absolute;
    left: 0;
    z-index: -1;
    visibility: hidden;
}

@media (max-width: 768px) {
    .ants-row{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
    }
    .ants{
        padding-top: 40px;
        h1{
            padding-bottom: 40px;
        }
    }
    .ant{
        display: flex;
        align-content: center;
        margin: 0px;
        width: 100%;
        white-space: normal;
        &:nth-child(odd) {
            margin: auto;
            align-items: center;
            padding: 0px;
            padding-bottom: 40px;
            p {
                text-align: unset
            }
        }
        &:nth-child(even){
            margin: auto;
            align-items: center;
            padding: 0px;
            padding-bottom: 40px;
            p {
                text-align: unset
            }
        }
    }
}

@media (min-width: 480px) and (max-width: 1024px) and (orientation: landscape) {

    .ant:nth-child(even) {
        padding-right: 0px;
        white-space: normal;
    }
    .ant:nth-child(odd) {
        padding-left: 0px;
        white-space: normal;

    }

}

@media (max-width: 576px) {
    .hidden-ant {
        width: 100%;
    }
}


@import "./OurTradition/ourtradition";