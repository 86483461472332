//startup-idea
.startup-idea{
    padding-top: 80px;
    img{
      width: 100%;
    }
    .image-container{
      img{
        // position: absolute;
        z-index: 1;
      }
    }
    .rocket-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }
    .box {
        position: absolute;
        z-index: 6;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
        height: 200px;
        margin: 0 auto 0 auto;
        transform-origin: bottom;
        width: 200px;
        img{
          position: absolute;
        }
    }
    .bounce {
        position: absolute;
        // padding-top: 50px;
        top: 15vh;
        img{
          display: flex;
          justify-content: center;
          height: 170%;
        }
        animation-name: bounce;
        animation-timing-function: ease;
    }

    @keyframes bounce {
      0%   { transform: translateY(0); }
      50%  { transform: translateY(-50px); }
      100% { transform: translateY(0); }
    }

  }
  .startup-idea-button{
    width: 180px;
    height: 48px;
    box-shadow: none;
    border-radius: 4px;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0.93px;
    background-color:#ffce45;
    border-color:#ffce45;
    color: #27262e;
    :focus{
      outline: none;
    }
  }

  @media (max-width: 768px) {
    .startup-idea{
      padding-top: 0px;
      .box {
        height: 60px;
        width: 60px;
      }
      .bounce {
        position: absolute;
        padding-top: 20px;
        top: 8vh;
        img{
          display: flex;
          justify-content: center;
        }
      }
      .startup-idea-button{
        display: block;
        margin-left: 0;
      }
    }
  }
