body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
button {
  border: none;
  outline: none;
}
button:focus {
  outline: none;
  border: none;
}
button:hover {
  opacity: 0.8;
}

.transition-group {
  position: relative;
  .route-section {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
  }
}

.app--wrapper {
  .fade-enter {
    opacity: 0.01;
  }
  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 400ms ease-in;
  }
  .fade-exit {
    opacity: 1;
  }

  .fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 400ms ease-in;
  }
}

.jump-to-top {
  margin-bottom: 50px;
  margin-top: 50px;
  .jump-to-top--container {
    display: inline-block;
    text-align: center;
    color: #27262e;
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
    img {
      width: 53px;
      height: 83px;
      padding-bottom: 15px;
    }
  }
  h5 {
    letter-spacing: 1.07px;
    font-size: 15px;
    font-weight: bold;
    padding: 0px;
  }
}
h1 {
  font-size: 40px;
  margin: 0px;
  line-height: 1.58;
  letter-spacing: -2.22px;
  font-weight: bold;
  color: #212121;
  font-weight: bold;
}
h5 {
  font-size: 14px;
  line-height: 1.14;
  letter-spacing: -1.17px;
  color: #212121;
  padding-left: 10px;
}
h2 {
  font-size: 28px;
  font-weight: 600;
  line-height: 1.14;
  letter-spacing: -1.17px;
  color: #212121;
}
.Oval {
  width: 18px;
  height: 18px;
  background-color: #000000;
  border-radius: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.p-header {
  line-height: 1.88;
}
p {
  font-size: 16px;
  font-weight: 300;
  color: #333333;
  font-weight: 300;
  line-height: 1.66;
}
.one-row {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  h1,
  h2 {
    line-height: 1.1;
    text-align: left;
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  p {
    line-height: 22px;
    font-weight: 400;
    text-align: left;
  }
  .p-header {
    line-height: 22px;
    margin-top: 20px;
    text-align: left;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(-2%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes shrink {
  0% {
    width: 95%;
  }
  100% {
    width: 90%;
  }
}

@import "./Home/home";
@import "./Navbar/navbar";
@import "./Footer/footer";
@import "./Team/team";
@import "./EngagementModel/engagementmodels";
@import "./Jobs/jobs";
@import "./Contact/contact";
@import "./Testimonials/testimonials";
@import "./Projects/projects";
@import "./Gallery/gallery";
