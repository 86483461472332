.our-tradition{
    padding-top: 150px;
    p{
        padding-top: 14px;
        text-align: center;
    }
   
    h1{
        text-align: center;
    }
    .Tilt{
        .Tilt-inner{
            img{
                width: 70%;
            }
        }
    }
}