
.testimonials{
    h1{
        font-size: 40px;
        font-weight: bold;
        line-height: 1.58;
        letter-spacing: -2.22px;
        color: #212121;
    }
    p{
        opacity: 0.8;
        font-size: 16px;
        font-weight: 300;
        line-height: 1.88;
        text-align: center;
        color: #333333;
    }
    h6{
        letter-spacing: 1.17px;
        font-size: 14px;
        text-transform: uppercase;
    }
    h4{
        font-size: 28px;
        line-height: 1.14;
        letter-spacing: -1.17px;
        font-weight: 600;
    }
    white-space: pre-wrap;
    .testimonials-header{
        padding-top: 150px;
        padding-bottom: 125px;
    }
}

@import "./TestimonialsItem/testimonialsitem";
