//join-us
.join-us{
    padding-top: 80px;
    h2, h5, p {
      color: white;
      padding-left: 0px;
    }
    img{
      width: 100%;
    }
  .join-us--picture{
    padding-top: 0px;
    background: url('https://imagizer.imageshack.com/img924/7999/zirnDB.png') no-repeat center center;
    object-fit: fill;
    height: 100vh;
    background-size: cover;
  }
  .join-us--col{
    background-image: linear-gradient(325deg, rgba(0, 0, 0, 0), #000000);
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    padding: 70px;
  }
  .join-us--button{
    width: 180px;
    height: 48px;
    box-shadow: none;
    border-radius: 4px;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0.93px;
    background-color:#ffce45;
    border-color:#ffce45;
    margin-top: 26px;
    margin-bottom: 42px;
    color: #27262e;
  }
}

  @media (max-width: 768px) {
    .join-us{
      padding-top: 40px;
      .join-us--picture{
        height: 70vh;
      }
      .join-us--col{
        height: 70vh;
        padding: 15px;
      }
    }
  }