.set-of-skills{
    padding-bottom: 80px;
    h1{
        padding-bottom: 80px;
    }
}
@media (max-width: 768px) {
    .set-of-skills{
        padding-bottom: 40px;
    h1{
        padding-bottom: 40px;
    }
    }
}