//  Landing header


@keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.landing-header--wrapper{
    background: url('https://imageshack.com/a/img924/8678/LFh75f.png') no-repeat center center;
    padding-top: 0px;
    height: 72vh;
    background-size: cover;
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 1;
    position: relative;
    overflow: hidden;
    margin-top: -1px;

    #background-wrap {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      height: 60vh;
      z-index: 2;
    }

    /* KEYFRAMES */

    @-webkit-keyframes animateCloud {
        0% {
            margin-left: -1000px;
        }
        100% {
            margin-left: 100%;
        }
    }

    @-moz-keyframes animateCloud {
        0% {
            margin-left: -1000px;
        }
        100% {
            margin-left: 100%;
        }
    }

    @keyframes animateCloud {
        0% {
            margin-left: -100%;
        }
        100% {
            margin-left: 100%;
        }
    }

    @-webkit-keyframes animateLateCloud {
      0% {
          margin-left: -200px;
      }
      100% {
          margin-left: 100%;
      }
    }

    @-moz-keyframes animateLateCloud {
        0% {
            margin-left: -200px;
        }
        100% {
            margin-left: 100%;
        }
    }

    @keyframes animateLateCloud {
      0% {
          margin-left: -200%;
      }
      100% {
          margin-left: 100%;
      }
  }

    /* ANIMATIONS */

    .x1 {
      -webkit-animation: animateLateCloud 40s linear infinite;
      -moz-animation: animateLateCloud 40s linear infinite;
      animation: animateLateCloud 40s linear infinite;
      img{
        height: 20px;
      }
    }

    .x2 {
      -webkit-animation: animateLateCloud 23s linear infinite;
      -moz-animation: animateLateCloud 23s linear infinite;
      animation: animateLateCloud 23s linear infinite;
      img{
        height: 30px;
      }
    }

    .x3 {
      -webkit-animation: animateLateCloud 25s linear infinite;
      -moz-animation: animateLateCloud 25s linear infinite;
      animation: animateLateCloud 25s linear infinite;
      img{
        height: 40px;
      }
    }

    .x4 {
      -webkit-animation: animateCloud 35s linear infinite;
      -moz-animation: animateCloud 35s linear infinite;
      animation: animateCloud 35s linear infinite;
      img{
        height: 20px;
      }
    }

    .x5 {
      -webkit-animation: animateCloud 15s linear infinite;
      -moz-animation: animateCloud 15s linear infinite;
      animation: animateCloud 15s linear infinite;
      img{
        height: 40px;
      }
    }

    .x6 {
      -webkit-animation: animateCloud 26s linear infinite;
      -moz-animation: animateCloud 26s linear infinite;
      animation: animateCloud 26s linear infinite;
      img{
        height: 30px;
      }
    }



  .landing-header--text{
    z-index: 5;
    margin-bottom: 0px;
    font-weight: bold;
    font-size: 40px;
    line-height: 1.58;
    letter-spacing: -1.22px;
    color: #ffffff;
    background: transparent;
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  .landing-header--image{
    img{
      width: 100%;
    }
    position: absolute;
    z-index: 5;
    bottom: 0;
  }
}

@media (max-width: 768px) {
  .landing-header--wrapper{
    height: 58vh;
    margin-top: 0px;
    #background-wrap {
      height: 40vh;
    }

    /* ANIMATIONS */

    .x1 {
      -webkit-animation: animateCloud 8s linear infinite;
      -moz-animation: animateCloud 8s linear infinite;
      animation: animateCloud 8s linear infinite;
      img{
        height: 10px;
      }
    }

    .x2 {
      -webkit-animation: animateCloud 4s linear infinite;
      -moz-animation: animateCloud 4s linear infinite;
      animation: animateCloud 4s linear infinite;
      img{
        height: 15px;
      }
    }

    .x3 {
      -webkit-animation: animateCloud 4s linear infinite;
      -moz-animation: animateCloud 4s linear infinite;
      animation: animateCloud 4s linear infinite;
      img{
        height: 20px;
      }
    }

    .x4 {
      -webkit-animation: animateCloud 6s linear infinite;
      -moz-animation: animateCloud 6s linear infinite;
      animation: animateCloud 6s linear infinite;
      img{
        height: 10px;
      }
    }

    .x5 {
      -webkit-animation: animateCloud 6s linear infinite;
      -moz-animation: animateCloud 6s linear infinite;
      animation: animateCloud 6s linear infinite;
      img{
        height: 20px;
      }
    }

    .x6 {
      -webkit-animation: animateCloud 8s linear infinite;
      -moz-animation: animateCloud 8s linear infinite;
      animation: animateCloud 8s linear infinite;
      img{
        height: 15px;
      }
    }

    .landing-header--text{
      line-height: 1.1;
      top: 35%;
      text-align: center;
    }
    .landing-header--image{
      transform: scale(1.9);
    }
  }
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .landing-header--wrapper{
    height: 69vh;
    #background-wrap {
      height: 57vh;
    }

    .landing-header--text{
      line-height: 1.1;
      top: 45%;
      text-align: center;
    }
    .landing-header--image{
      transform: scale(1.3);
    }
  }
}

@media (min-width: 480px) and (max-width: 1024px) and (orientation: landscape) {
  .landing-header--wrapper{
      height: 95vh;
      #background-wrap {
        height: 72vh;
      }

      .landing-header--text{
        line-height: 1.1;
        top: 45%;
        text-align: center;
      }
      .landing-header--image{
        transform: scale(1.1);
      }
    }
}

@media (min-width : 768px) and (max-width : 1024px)  {
  .landing-header--wrapper{
    height: 50vh;
    #background-wrap {
      height: 35vh;
    }

    .landing-header--text{
      line-height: 1.1;
      top: 45%;
      text-align: center;
    }
    .landing-header--image{
      transform: scale(1.5);
    }
  }
}
@media (min-width : 768px) and (max-width : 1024px) and (orientation: landscape) {
  .landing-header--wrapper{
    height: 88vh;
    #background-wrap {
      height: 88vh;
    }

    .landing-header--text{
      line-height: 1.1;
      top: 35%;
      text-align: center;
      font-size: 30px;
    }
    .landing-header--image{
      transform: scale(1);
    }
  }
}
