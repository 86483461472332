// Workflow
.workflow{
  padding-top: 80px;
  p.p-text{
    padding-bottom: 80px;
    margin-bottom: 0px;
  }
  img{
    display: block;
    max-height: 300px;
  }
  .workflow-column{
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    img{
      align-self: center;
    }
  }
  .workflow> h2,h5,img{
    padding-bottom:15px;
    letter-spacing: 1.17px;
  }
  h5{
    margin-bottom: 15px;
  }
  .workflow-row{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}

@media (max-width: 768px) {
  .workflow{
    padding-top: 40px;
    p.p-text{
      padding-bottom: 40px;
      margin-bottom: 0px;
    }
    img{
      height: 50%;
    }
    .workflow-column{
      padding-right: 0px;
      padding-left: 0px;
      height: 100%;
      img{
        align-self: flex-start;
      }
    }
    .Oval{
      margin-right: 10px;
    }
    h5{
      padding-left: 0px;
    }
  }
}

