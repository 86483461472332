@media (max-width: 768px) {
  p.p-header{
    padding-bottom:0px;
    margin-bottom: 0px;
  }
}



@import "./Diversity/diversity";
@import "./OurAim/ouraim";
@import "./Fun/fun";
@import "./JoinUs/joinus";
@import "./StartupIdea/startupidea";
@import "./LandingHeader/landingheader";
@import "./Workflow/workflow";