.contact{
    .contact-header{
        padding-top: 150px;
    }
    .form{
        padding-bottom: 100px;
        .one-row{
            display:flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            h6{
                color: #333333;
                font-size: 16px;
                margin: 0px;
                margin-right: 35px;
            }
            .input-area{
                border-radius: 4px;
                border: solid 1px #dddddd;
                background-color: #ffffff;
                height: 50px;
                flex: auto;
                color: black;
                &::placeholder {
                    padding-right: 15px;
                    color: gray;
                }
            }
            .send-button{
                border-radius: 4px;
                box-shadow: 0 4px 5px 0 rgba(255, 201, 3, 0.27);
                background-color: #ffce45;
                width: 219px;
                height: 48px;
                color: #27262e;
                text-transform: uppercase;
                font-size: 13px;
                font-weight: bold;
                letter-spacing: 0.93px;
                border: none;
                outline: none;
                span{
                    &.submit {
                        display: block; 
                    }
                    
                    &.loading {
                        display: none;
                    }
                    
                    &.check {
                        display: none;
                    }
                }
                &.active{
                    span{
                        &.submit {
                            display: none; 
                        }
                        
                        &.loading {
                            display: block;
                        }
                        
                        &.check {
                            display: none;
                        }
                    }
	
                }
                &.finished {
                   span{
                    &.submit {
                        display: none; 
                    }
                    
                    &.loading {
                        display: none;
                    }
                    
                    &.check {
                        display: block;
                        animation: scale 0.5s linear;
                    }
                    @keyframes scale {
                        0%   { transform: scale(10); }
                        50%  { transform: scale(0.2); }
                        70%  { transform: scale(1.2); }
                        90%  { transform: scale(0.7); }
                        100% { transform: scale(1); }
                    }
                   }
                    
                }
                
               
               
            }
            
        }
        textarea{
            border: solid 1px #dddddd;
            background-color: #ffffff;
            border-radius: 4px;
            padding-top: 13px;
            color: black;
            resize: none;
        }
        textarea::placeholder{
            color: gray;
        }
    }
}

@media (max-width: 768px) {
    .contact{
        .form{
            .one-row{
                h6{
                    text-align: left;
                    font-size: 12px;
                }
            }
        }
    }
   
}

@media (min-width: 480px) and (max-width: 1024px) and (orientation: landscape) {
    .contact{
        .form{
            .one-row{
                h6{
                    padding: 0px 0px !important;
                    margin-right: 0px;
                    text-align: center;
                }
            }
        }
    }
}