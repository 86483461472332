.welcome-to-our-home{
    padding-bottom: 80px;
    p{
        margin: 0px;
        font-size: 16px;
        font-weight: 300;
        line-height: 1.88;
        color: #ffffff;
        .p-strong{
            font-weight: bold;
            letter-spacing: 1.17px;
        }
    }
    .p-header{
        padding-bottom: 80px;
    }
    img{
        width: 100%
    }
    .leaf-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }
    .box {
        position: absolute;
        z-index: 6;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
        height: 200px;
        margin: 0 auto 0 auto;
        transform-origin: bottom;
        width: 200px;
        img{
          position: absolute;
        }
    }
    .bounce {
        position: absolute;
        img{
          display: flex;
          justify-content: center;
        }
        animation-name: bounce;
        animation-timing-function: ease;
    }

    @keyframes bounce {
      0%   { transform: translateY(0); }
      50%  { transform: translateY(-40px); }
      100% { transform: translateY(0); }
    }
}
@media (max-width: 768px) {
    .welcome-to-our-home{
        padding-bottom: 40px;
        br{
            display: none;
        }
        p{
            line-height: 1.3;
            white-space: normal;
        }
        .p-strong {
            display: flex;
            justify-content: flex-start;
            margin-right: 0px !important;
        }
        .box {
          height: 60px;
          width: 60px;
        }
        .bounce {
          position: absolute;
          top: 15vh;
          img{
            display: flex;
            justify-content: center;
          }
        }
        
    }
}

@media (min-width: 480px) and (max-width: 1024px) and (orientation: landscape) {
    .welcome-to-our-home{
        padding-bottom: 40px;
        br{
            display: none;
        }
        p{
            line-height: 1.3;
        }
        .p-strong {
            display:inline !important;
        }
        .box {
          height: 100px;
          width: 100px;
        }
        
    }
}