.jobs{
    h1{
        font-size: 40px;
        font-weight: bold;
        line-height: 1.58;
        letter-spacing: -2.22px;
        color: #212121;
    }
    p{
        opacity: 0.8;
        font-size: 16px;
        font-weight: 300;
        line-height: 1.88;
        text-align: center;
        color: #333333;
        white-space: pre-wrap;
    }

    .job{
        margin-top: 45px;
        width: 100%;
        margin-bottom: 45px;
    }
    .job-position-header{
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background: #0f0f19;
        padding: 48px 100px;
        h5, h3{
            padding: 0px;
            color: #ffffff;
        }
        h5{
            text-transform: uppercase;
            font-size: 17px;
            letter-spacing: 1.17px;
        }
        h3{
            line-height: 0.89;
            letter-spacing: -1.5px;
            font-size: 36px;
            font-weight: 600;
        }
        .job-position-header--titles{
            text-align: left;
            padding-left: 60px;
        }
    }
    .job-position-text{
        padding-top: 53px;
        padding-bottom: 80px;
        padding-right: 100px;
        padding-left: 100px;
        box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.08);
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        p{
            text-align: left;
        }
        .text-container{
            // height: 150px;
            overflow-y: hidden;
            text-overflow: ellipsis;
            transition: height 0.5s ease-out;
            &.full-text{
                transition: height 0.5s ease-out;
                // height: auto;
            }
        }
        .button-container{
            width: 219px;
            height: 48px;
            display: flex;
            justify-content: flex-start;
            padding: 40px 0px;
            :focus{
                outline: none;
            }
            
            .read-more--button{
                width: 219px;
                height: 48px;
                border-radius: 4px;
                box-shadow: 0 4px 5px 0 rgba(255, 201, 3, 0.27);
                background-color: #ffce45;
                font-size: 13px;
                font-weight: bold;
                letter-spacing: 0.93px;
                color: #27262e;
                border: none;

            }
            .read-less--button{
                width: 219px;
                height: 48px;
                box-shadow: none;
                border-radius: 4px;
                border: solid 2px #27262e;
                background-color: white;
                font-size: 13px;
                font-weight: bold;
                letter-spacing: 0.93px;
                color: #27262e;
            }
        }
    }
}


@media (max-width: 768px) {
    .jobs{
        p, h3, h5{
            line-height: 1.3;
        }
        .job{
            margin-top: 45px;
            width: 100%;
        }
        .job-position-header{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            background: #0f0f19;
            padding: 0px;
            img{
                display: none;
            }
            h3{
                font-size: 20px;
                padding-bottom: 20px;
            }
            h5{
                padding: 20px 10px;
                font-size: 12px;
                margin: 0px;
            }
            .job-position-header--titles{
                text-align: center;
                padding-left: 0px;
            }
        }
        .job-position-text{
            padding: 20px 10px;
            p{
                text-align: left;
                font-size: 15px;
            }
            .text-container{
                height: 150px;
                overflow-y: hidden;
                text-overflow: ellipsis;
                &.full-text{
                    height: auto;
                }
            }
            .button-container{
                display: flex;
                justify-content: center;
                padding: 0px;
                margin-top: 25px;
                width: 50%;
            }
        }
    }
}


@import "./JobForYou/jobforyou";
@import "./JobPositions/jobpositions";