.job-for-you{
    padding-top: 150px; 
    img{
        width: 65%;
        height: 100%;
    }
    h1{
        padding-bottom: 15px;
    }
}

@media (max-width: 768px) {
    .job-for-you{
        h1{
            line-height: 1.1;
            text-align: center;
        }
    }
}