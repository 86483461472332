.navbar {
  position: absolute;
  width: 100%;
  z-index: 10;
}
.navbar-title {
  display: flex;
  justify-content: flex-start;
  padding-bottom: 0px;
}
.navbar-nav {
  margin-left: auto;
}
.navbar-title--name {
  letter-spacing: 1.17px;
  font-weight: 400;
  color: white;
  flex-wrap: nowrap;
  padding-top: 52px;
  &.black-logo-text{
    color: black;
  }
  .ant-strong{
    font-weight: 900;
  }
}
.navbar-title--logo {
  padding-top: 28px;
}
.navbar-brand {
  display: flex;
}
.nav-item{
  display: inline-block;
  font-size: 0;
  padding-right: 15px;
  
  a{
    display:inline-block;
    text-align:center;
    font-weight: normal;
    position: relative;
    text-decoration: none;
    color: #9294ad;

    &:hover {
      font-weight:bold;
    }
    &::before{
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: white;
      visibility: hidden;
      -webkit-transform: scaleX(0);
      transform: scaleX(0);
      -webkit-transition: all 0.3s ease-in-out 0s;
      transition: all 0.3s ease-in-out 0s;
    }
    &:hover:before {
      visibility: visible;
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
    }
    &::after {
        display: block;
        content: attr(title);
        font-weight: bold;
        height: 0;
        overflow: hidden;
        visibility: hidden;
    }
  }
  &.home{
    a{
      color: white;
      font-weight: bold;
    }
  }
  &.team{
    a{
      font-weight: bold;
    }
  }
  &.how-we-work{
    background: transparent;
    a{
      color: white;
      font-weight: bold;
    }
  }
  &.jobs{
    a{
      font-weight: bold;
    }
  }
  &.contact{
    a{
      font-weight: bold;
    }
  }
  &.testimonials{
    a{
      font-weight: bold;
    }
  }
  &.projects{
    a{
      font-weight: bold;
    }
  }
  &.gallery{
    a{
      font-weight: bold;
    }
  }
}
.nav-link {
  display: block;
  padding: 0.5rem 0.5rem;
  font-size: 14px;
  letter-spacing: 0.5px;
  color: #46496d;
  &:hover {
    color: white;
    font-weight: bold;
  }
  &.black-link{
    color:  black;
    &:hover{
      font-weight: bold;
    }
    &::before{
      background-color: black;
    }
  }
}




.navbar-button {
  display: none;
}

@media (max-width: 768px) {
    .navbar .container{
      display: flex;
      align-items: center;
    }
    .navbar-brand{
      display: flex;
      align-items: flex-end;
      .navbar-title--logo{
        height: 30%;
        width: 30%;
        padding-top: 0px;
        padding-bottom: 0px;
      }
      .navbar-title--name{
        padding: 0px;
      }
    }
    .nav-item {
        opacity: 0;
        animation: 1s appear forwards,
    }
    .nav-link {
        font-size: 25px;
        color: white;
        a{
          min-width: 156px;
        }
        &.black-link{
          color:  white;
          font-weight: normal;
          &:hover{
            font-weight: bold;
          }
        }
    }
    .navbar {
        position: fixed;
        transition: all 0.3s ease-in;
        background: transparent;
        &.filled-nav {
            background: black;
            transition: all 0.3s ease-in;
            .navbar-brand{
              p{
                color: white;
              }
            }
        }
    }
    .navbar-nav {
        margin-left: 0px;
    }
    .navbar-collapse {
        height: 0;
        position: absolute;
        width: 100%;
        transition: height 0.3s ease;
        z-index: 2;
        background: black;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        &.show {
            height: 100vh;
        }
    }
  .navbar-button {
    height: 32px;
    width: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 4px;
    z-index: 5;
    .line {
      height: 2px;
      width: 20px;
      background: white;
      transition: all 0.2s ease;
      &.line-top {
        transform: none;
        transform-origin: top left;
        margin-bottom: 5px;
      }
      &.line-middle {
        opacity: 1;
        transform: none;
      }
      &.line-bottom {
        transform: none;
        transform-origin: top left;
        margin-top: 5px;
      }
    }
    &.navbar-open {
      & .line-top {
        transform: rotate(45deg);
      }
      & .line-middle {
        opacity: 0;
        transform: translateX(-16px);
      }
      & .line-bottom {
        transform: translateX(-1px) rotate(-45deg);
      }
    }
  }
  .navbar-button{
    border: none;
    background: none;
    &:focus{
      outline: none;
    }
    &.black-button{
      .line{
        background: black;
      }
    }

  }
}


/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .navbar-brand{
    transform: scale(0.8);
  }
  .navbar-collapse {
    transform: scale(0.8);
  }
  .nav-item{
    padding-right: 10px;
  }
}

@media (min-width : 768px) and (max-width : 1024px) and (orientation: landscape) {
  .navbar .container{
    display: flex;
    align-items: center;
  }
  .navbar-brand{
    display: flex;
    align-items: flex-end;
    .navbar-title--logo{
      height: 30%;
      width: 30%;
      padding-top: 0px;
      padding-bottom: 0px;
    }
    .navbar-title--name{
      padding: 0px;
    }
  }
  .nav-item {
      opacity: 0;
      animation: 1s appear forwards,
  }
  .nav-link {
      font-size: 25px;
      color: white;
      a{
        min-width: 156px;
      }
      &.black-link{
        color:  white;
        font-weight: normal;
        &:hover{
          font-weight: bold;
        }
      }
  }
  .navbar {
      position: fixed;
      transition: all 0.3s ease-in;
      background: transparent;
      &.filled-nav {
          background: black;
          transition: all 0.3s ease-in;
          .navbar-brand{
            p{
              color: white;
            }
          }
      }
  }
  .navbar-nav {
      margin-left: 0px;
  }
  .navbar-collapse {
      height: 0;
      position: absolute;
      width: 100%;
      transition: height 0.3s ease;
      z-index: 2;
      background: black;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      &.show {
          height: 100vh;
      }
  }
.navbar-button {
  height: 32px;
  width: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 4px;
  z-index: 5;
  .line {
    height: 2px;
    width: 20px;
    background: white;
    transition: all 0.2s ease;
    &.line-top {
      transform: none;
      transform-origin: top left;
      margin-bottom: 5px;
    }
    &.line-middle {
      opacity: 1;
      transform: none;
    }
    &.line-bottom {
      transform: none;
      transform-origin: top left;
      margin-top: 5px;
    }
  }
  &.navbar-open {
    & .line-top {
      transform: rotate(45deg);
    }
    & .line-middle {
      opacity: 0;
      transform: translateX(-16px);
    }
    & .line-bottom {
      transform: translateX(-1px) rotate(-45deg);
    }
  }
}
.navbar-button{
  border: none;
  background: none;
  &:focus{
    outline: none;
  }
  &.black-button{
    .line{
      background: black;
    }
  }

}
}
