.projects{
    h2{
        font-size: 40px;
        font-weight: 500;
    }
    h3{
        font-size: 28px;
        font-weight: 600;
        line-height: 1.14;
        letter-spacing: -1.17px;
    }
    h5{
        letter-spacing: 1.17px;
        font-size: 14px;
    }
    p{
        font-size: 16px;
        font-weight: 300;
        line-height: 1.63;
    }
    .projects-header{
        padding-top: 50px;
        p{
            padding-bottom: 80px;
            padding-top: 14px;
        }
        .case-studies-header{
            padding-bottom: 20px;
        }
        .our-other-clients-header{
            margin-top: 150px;
        }

    }
    .subtitle{
        text-transform: uppercase;
    }
    .project{
        text-align: left;
        img{
            width: 100%;
        }
        .first-text-container{
            column-count: 2;
            column-gap: 80px;
        }
        .second-part{
            display: flex;
            .second-part-img{
                padding-left: 0px;
                padding-right: 0px;
            }
            .second-part-text{
                display: flex;
                flex-direction: column;
                justify-content: center;
                h2{
                    padding-right: 48px;
                }
                &.left-text{
                    padding-left: 70px;
                }
            }

        }
        .right{
            flex-direction: row;
        }
        .left{
            flex-direction: row-reverse;
        }

    }
    .our-other-clients{
        img{
            width: 100%;
            opacity: 0.4;
        }
    }
}

@media (max-width: 768px) {
    .projects{
        h2{
            font-size: 28px;
        }
        h3{
            font-size: 28px;
        }
        h5{
            font-size: 14px;
        }
        p{
            font-size: 16px;
        }
        .projects-header{
            p{
                padding-bottom: 40px;
                padding-top: 14px;
                margin: 0px;
            }
            .case-studies-header{
                padding-bottom: 10px;
            }

        }
        .project{
            .first-part{
                padding: 25px 15px;
                .first-text-container{
                    column-count: 1;
                }

            }
            .second-part {
                img{
                    width: 100%;
                }
                display: flex;
                flex-direction: column;
                .second-part-text{
                    padding: 25px 15px;
                    h2{
                        padding-right: 15px;
                        font-weight: bold;
                        margin-bottom: 35px;
                    }
                    p{
                        padding-right: 15px;
                    }
                    &.left-text{
                        padding-left: 15px;
                    }
                }
                &.right{
                    flex-direction: column;
                }
                &.left{
                    flex-direction: column;

                }
            }
        }
    }
}